import React from "react";
import Geographic from "../components/Boards/Ipdetails";

const Ipdetails = () => {
  return (
    <div className="page-content">
      <Geographic />
    </div>
  );
};

export default Ipdetails;
