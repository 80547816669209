import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "./IpChart.css"; // Import the CSS file

Chart.register(...registerables);

const IpChart = () => {
  const [userAccessData, setUserAccessData] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [batchSize] = useState(10);
  const [chartData, setChartData] = useState({
    countries: {},
    cities: {},
    regions: {},
  });
  const [loading, setLoading] = useState(true);
  const [allDataFetched, setAllDataFetched] = useState(false);

  useEffect(() => {
    const fetchUserAccessData = async () => {
      try {
        const response = await fetch(
          "https://dashboard-api.digihut.store/api/users/useraccess"
          // "http://localhost:5000/api/users/useraccess"
        );
        const data = await response.json();
        setUserAccessData(data);
      } catch (error) {
        console.error("Error fetching user access data:", error);
      }
    };

    fetchUserAccessData();
  }, []);

  useEffect(() => {
    if (userAccessData.length > 0) {
      fetchIPDetails(currentBatch);
    }
  }, [currentBatch, userAccessData]);

  const fetchIPDetails = async (batch) => {
    setLoading(true);

    const start = batch * batchSize;
    const end = Math.min(start + batchSize, userAccessData.length);
    const batchData = userAccessData.slice(start, end);

    const accessDataWithIPDetails = await Promise.all(
      batchData.map(async (access) => {
        const ipResponse = await fetch(
          `http://ip-api.com/json/${access.ip_address}`
        );
        const ipData = await ipResponse.json();
        return { ...access, ipDetails: ipData };
      })
    );

    const countries = {};
    const cities = {};
    const regions = {};

    accessDataWithIPDetails.forEach((access) => {
      if (access.ipDetails && access.ipDetails.status === "success") {
        const { country, city, regionName } = access.ipDetails;
        if (country) countries[country] = (countries[country] || 0) + 1;
        if (city) cities[city] = (cities[city] || 0) + 1;
        if (regionName) regions[regionName] = (regions[regionName] || 0) + 1;
      }
    });

    setChartData((prevChartData) => ({
      countries: { ...prevChartData.countries, ...countries },
      cities: { ...prevChartData.cities, ...cities },
      regions: { ...prevChartData.regions, ...regions },
    }));

    setLoading(false);
    setCurrentBatch((prevBatch) => {
      const newBatch = prevBatch + 1;
      if (newBatch * batchSize >= userAccessData.length) {
        setAllDataFetched(true);
      }
      return newBatch;
    });
  };

  const generateChartData = (data, label) => {
    return {
      labels: Object.keys(data),
      datasets: [
        {
          label: label,
          data: Object.values(data),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <div className="ip-chart-container">
      <h2>IP Address Statistics by Country</h2>
      <div className="chart-container">
        <Bar data={generateChartData(chartData.countries, "Countries")} />
      </div>

      <h2>IP Address Statistics by City</h2>
      <div className="chart-container">
        <Bar data={generateChartData(chartData.cities, "Cities")} />
      </div>

      <h2>IP Address Statistics by Region</h2>
      <div className="chart-container">
        <Bar data={generateChartData(chartData.regions, "Regions")} />
      </div>

      {loading && <div className="loading">Loading data...</div>}
      {!loading && !allDataFetched && (
        <button
          className="load-more-button"
          onClick={() => fetchIPDetails(currentBatch)}
        >
          Load More Data
        </button>
      )}
      {allDataFetched && !loading && (
        <div className="all-fetched">All data has been fetched.</div>
      )}
    </div>
  );
};

export default IpChart;
