import React, { useEffect, useState } from "react";

const UserAccessCard = () => {
  const [userAccessData, setUserAccessData] = useState([]);

  useEffect(() => {
    // Fetch user access data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "http://localhost:5000/api/users/useraccess"
          "https://dashboard-api.digihut.store/api/users/useraccess"
        );
        const data = await response.json();
        setUserAccessData(data);
      } catch (error) {
        console.error("Error fetching user access data:", error);
      }
    };

    fetchData();
  }, []);

  // Inline styles
  const cardContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
    padding: "20px",
  };

  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "2px",
    padding: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    textAlign: "center",
  };

  const idStyle = {
    fontSize: "14px",
    color: "#777",
    marginBottom: "10px",
  };

  const dateStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const ipStyle = {
    fontSize: "16px",
    color: "#333",
  };

  return (
    <div style={cardContainerStyle}>
      {userAccessData.map((access) => (
        <div key={access.id} style={cardStyle}>
          <div style={idStyle}>ID: {access.id}</div>
          <div style={dateStyle}>
            Access Date: {new Date(access.access_date).toLocaleString()}
          </div>
          <div style={ipStyle}>IP Address: {access.ip_address}</div>
        </div>
      ))}
    </div>
  );
};

export default UserAccessCard;
