import React from "react";
import IpChart from "../components/Boards/IpChart";

const Ipstatics = () => {
  return (
    <div className="page-content">
      <IpChart />
    </div>
  );
};

export default Ipstatics;
