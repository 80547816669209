import React from "react";
import UserAccessCard from "../components/Boards/UserAccessCard";

const About = () => {
  return (
    <div className="page-content">
      <UserAccessCard />
    </div>
  );
};

export default About;
