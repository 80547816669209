import React from "react";
import { NavLink } from "react-router-dom";
import homeicon from "../Assets/home.png";
import statsicon from "../Assets/stats.png";
import ip from "../Assets/ip.png";

import Geographic from "../Assets/Geographic.png";
import "./CSS/Sidebar.css";

const Sidebar = ({ isCollapsed, onToggleSidebar }) => {
  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active-menu" : "")}
          >
            <img src={homeicon} alt="Home" className="sidebar-icons" />
            {!isCollapsed && <span>Home</span>}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? "active-menu" : "")}
          >
            <img src={ip} alt="About" className="sidebar-icons" />
            {!isCollapsed && <span>User Access</span>}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Ipdetails"
            className={({ isActive }) => (isActive ? "active-menu" : "")}
          >
            <img src={Geographic} alt="About" className="sidebar-icons" />
            {!isCollapsed && <span>Ip Details</span>}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/ip"
            className={({ isActive }) => (isActive ? "active-menu" : "")}
          >
            <img src={statsicon} alt="About" className="sidebar-icons" />
            {!isCollapsed && <span>Ip Stats</span>}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
