import React, { useState } from "react";
import notificationIcon from "../Assets/notification.png";
import profileIcon from "../Assets/profile.png";
import toggleSidebarIcon from "../Assets/toggle.png"; // Import your sidebar toggle icon
import "./CSS/Header.css"; // Import custom CSS for styling

const Header = ({ onToggleSidebar }) => {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);

  const toggleNotificationModal = () => {
    setNotificationOpen(!isNotificationOpen);
    setProfileOpen(false); // Close profile modal if open
  };

  const toggleProfileModal = () => {
    setProfileOpen(!isProfileOpen);
    setNotificationOpen(false); // Close notification modal if open
  };

  return (
    <div className="header">
      <div className="header-left">
        <img
          src={toggleSidebarIcon}
          alt="Toggle Sidebar"
          className="header-icon-Toggle"
          onClick={onToggleSidebar}
        />
      </div>
      <div className="header-right">
        <div className="icon-wrapper">
          <img
            src={notificationIcon}
            alt="Notifications"
            className="header-icon"
            onClick={toggleNotificationModal}
          />
          {isNotificationOpen && (
            <div className="modal">
              <p>No new notifications</p>
            </div>
          )}
        </div>
        <div className="icon-wrapper">
          <img
            src={profileIcon}
            alt="Profile"
            className="header-icon"
            onClick={toggleProfileModal}
          />
          {isProfileOpen && (
            <div className="modal">
              <p>Profile Settings</p>
              <p>Logout</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
