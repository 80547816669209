import React, { useEffect, useState } from "react";

const Geographic = () => {
  const [userAccessData, setUserAccessData] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const batchSize = 10;

  useEffect(() => {
    // Fetch user access data from the API
    const fetchUserAccessData = async () => {
      try {
        const response = await fetch(
          // "http://localhost:5000/api/users/useraccess"
          "https://dashboard-api.digihut.store/api/users/useraccess"
        );
        const data = await response.json();
        setUserAccessData(data);
        loadMoreData(data, 0);
      } catch (error) {
        console.error("Error fetching user access data:", error);
      }
    };

    fetchUserAccessData();
  }, []);

  const loadMoreData = async (data, startIndex) => {
    const newBatch = data.slice(startIndex, startIndex + batchSize);
    const batchWithIPDetails = await Promise.all(
      newBatch.map(async (access) => {
        const ipResponse = await fetch(
          `http://ip-api.com/json/${access.ip_address}`
        );
        const ipData = await ipResponse.json();
        return { ...access, ipDetails: ipData };
      })
    );
    setVisibleData((prevData) => [...prevData, ...batchWithIPDetails]);
    setCurrentIndex((prevIndex) => prevIndex + batchSize);
  };

  const handleLoadMore = () => {
    loadMoreData(userAccessData, currentIndex);
  };

  // Inline styles
  const cardContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
    padding: "20px",
  };

  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "2px",
    padding: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    textAlign: "center",
  };

  const idStyle = {
    fontSize: "14px",
    color: "#777",
    marginBottom: "10px",
  };

  const dateStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const ipStyle = {
    fontSize: "16px",
    color: "#333",
  };

  const ipDetailsStyle = {
    fontSize: "14px",
    color: "#555",
    marginTop: "10px",
    textAlign: "left",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "20px",
    textAlign: "center",
  };

  return (
    <div>
      <div style={cardContainerStyle}>
        {visibleData.map((access) => (
          <div key={access.id} style={cardStyle}>
            <div style={idStyle}>ID: {access.id}</div>
            <div style={dateStyle}>
              Access Date: {new Date(access.access_date).toLocaleString()}
            </div>
            <div style={ipStyle}>IP Address: {access.ip_address}</div>
            {access.ipDetails && (
              <div style={ipDetailsStyle}>
                <div>Country: {access.ipDetails.country}</div>
                <div>Region: {access.ipDetails.regionName}</div>
                <div>City: {access.ipDetails.city}</div>
                <div>ISP: {access.ipDetails.isp}</div>
              </div>
            )}
          </div>
        ))}
      </div>
      {currentIndex < userAccessData.length && (
        <div style={{ textAlign: "center" }}>
          <button style={buttonStyle} onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Geographic;
