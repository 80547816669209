import React, { useState, useEffect } from "react";
import axios from "axios";
import down from "../../Assets/down.png";
import "./AllUsers.css"; // Import custom CSS for styling

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isExpanded, setIsExpanded] = useState(true); // State for toggling

  useEffect(() => {
    // Fetch users from the API
    axios
      // .get("http://localhost:5000/api/users/all") // Adjust the URL to your API endpoint
      .get("https://dashboard-api.digihut.store/api/users/all") // Adjust the URL to your API endpoint
      .then((response) => {
        setUsers(response.data);
        console.log(response);
        setFilteredUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  useEffect(() => {
    // Filter users based on search term
    setFilteredUsers(
      users.filter((user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, users]);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="all-users">
      <div className="filter">
        <button onClick={toggleCollapse} className="toggle-button">
          <img src={down} className="toggle-button-icon"></img>
          {isExpanded ? "" : ""}
        </button>
        <input
          type="text"
          placeholder="Search by username..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {isExpanded && (
        <div className="user-cards">
          {filteredUsers.map((user) => (
            <div key={user.user_id} className="user-card">
              <h3>{user.username}</h3>
              <p>{user.email}</p>
              <p>Joined: {new Date(user.created_at).toLocaleDateString()}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AllUsers;
