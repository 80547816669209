import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Ipstatics from "./pages/Ipstats";
import Ipdetails from "./pages/iplocation";

import "./App.css"; // Import custom CSS for styling

const App = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const handleSidebarToggle = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <Router>
      <div className={`app ${isSidebarCollapsed ? "sidebar-collapsed" : ""}`}>
        <Header onToggleSidebar={handleSidebarToggle} />
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          onToggleSidebar={handleSidebarToggle}
        />
        <div className="main-content">
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/Ipdetails" element={<Ipdetails />} />
              <Route path="/ip" element={<Ipstatics />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
