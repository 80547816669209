import React, { useEffect, useState } from "react";

const UserCountCard = () => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    // Fetch user count from the API
    const fetchData = async () => {
      try {
        // const response = await fetch("http://localhost:5000/api/users/counts");
        const response = await fetch(
          "https://dashboard-api.digihut.store/api/users/counts"
        );
        const data = await response.json();
        setCount(data[0]?.count || "0");
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };

    fetchData();
  }, []);

  // Inline styles
  const cardStyle = {
    borderRadius: "2px",
    padding: "16px",

    margin: "20px auto",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const countStyle = {
    fontSize: "24px",
    color: "#333",
  };

  return (
    <div style={cardStyle}>
      <div style={titleStyle}>User Count</div>
      <div style={countStyle}>{count}</div>
    </div>
  );
};

export default UserCountCard;
