import React from "react";
import UserCountCard from "../components/Boards/Counts";
import AllUsers from "../components/Boards/AllUsers";

const Home = () => {
  return (
    <div className="page-content">
      <UserCountCard />
      <AllUsers />
    </div>
  );
};

export default Home;
